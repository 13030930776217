var map; // finally, map is global var;)

function new_map( $el ) {

	var $markers = $el.find('.marker');

	var args = {
		zoom: 14,
		center: new google.maps.LatLng(0, 0),
		draggable: true,
		scrollwheel: false,
		disableDoubleClickZoom: true,
		panControl: false,
		zoomControl: true,
		scaleControl: false,
		mapTypeControl: false,
		streetViewControl: false,
		overviewMapControl: false,
		mapTypeId: google.maps.MapTypeId.ROADMAP
	};

	map = new google.maps.Map( $el[0], args);

	map.markers = [];

	var styles = [{
		
	}];

	var styledMapOptions = {
		map: map
	}

	var mainmap = new google.maps.StyledMapType(styles,styledMapOptions);

	map.mapTypes.set('site_map', mainmap);
	map.setMapTypeId('site_map');

	$markers.each(function(){
    	add_marker( jQuery(this), map);
	});

	center_map(map);

	return map;
}
function add_marker($marker, map) {
	var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

	var image = {
		url: '/static/svg/src/icon-pin.svg',
		size: new google.maps.Size(56,66),
		origin: new google.maps.Point(0,0),
		anchor: new google.maps.Point(33,66),
		scaledSize: new google.maps.Size(56,66),
	};

	var marker = new google.maps.Marker({
		position	: latlng,
		map			: map,
		icon		: image,
		optimized: false,
	});

	map.markers.push(marker);

	if($marker.html()) {
		var infowindow = new google.maps.InfoWindow({
			content		: $marker.html()
		});
		google.maps.event.addListener(marker, 'click', function() {
			infowindow.open(map, marker);
		});
	}
}
function center_map(map) {
	var bounds = new google.maps.LatLngBounds();

	jQuery.each( map.markers, function(i, marker){
		var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
		bounds.extend(latlng);
	});
	if(map.markers.length == 1) {
		map.setCenter(bounds.getCenter());
		if($(window).width() > 767) {
			map.panBy(200, 0);
		}
		else {
			map.panBy(0, -220);
		}
	    map.setZoom(16);
	}
	else {
		map.fitBounds(bounds);
	}
}

jQuery(document).ready(function($){
	$('.gmap').each(function(){
		map = new_map( $(this) );
	});
});