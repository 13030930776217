 jQuery(function(){
	var body = $('body'),
		menuTrigger = $('.menu-trigger'),
		subnavTriggers = $('.sub-nav-wrap');

	menuTrigger.on('click', function(e) {
		e.preventDefault();
		body.toggleClass('menu-open');
	});

	subnavTriggers.each(function() {
		var trigger = $(this),
			subnav = $(this).find('.sub-nav');
		trigger.on('click', function() {
			subnav.slideToggle('fast');
		});

		$(document).bind('click', function (e) {
			if (!$('.main-nav').is(e.target) && $('.main-nav').has(e.target).length === 0) {
				subnav.slideUp('fast');
			}
		});
	});

	/* lng switch */
	$('.lang-switch').on('click', function(e) {
		e.preventDefault();
		var langsList = $('#langs');
		if(langsList.length > 0) {
			langsList.toggleClass('langs-open');
		}
	});
 });